@import "variables";
@import "mixins";

/*
* Offsets
* ================ */
$medias: ($screen-xs-min : xs, $screen-sm-min : sm, $screen-md-min : md, $screen-lg-min : lg, $screen-xl-min : xl);
$medias-no: (0: null);

$paddings: (0, 10px, 15px, 20px, 30px, 35px, 40px, 50px, 60px, 65px, 70px, 80px, 90px, 100px, 110px, 120px, 130px, 140px, 150px, 160px, 170px, 180px, 190px, 200px, 210px, 220px, 240px, 280px);
$paddings-rl: (0, 5px, 10px, 15px, 20px, 30px, 35px, 40px, 50px, 60px, 65px, 70px);

$margins: (-50px, -30px, 0, 10px, 15px, 20px, 25px, 30px, 35px, 40px, 45px, 50px, 60px, 70px, 80px, 90px, 100px, 110px, 120px, 130px, 140px, 150px, 160px, 170px, 180px, 190px, 200px, 250px, 280px);


// Elements
// -------------------------

h3 + h1 {
	margin-top: 10px;
}

.letter-spacing-0 {
	letter-spacing: 0;
}

// Responsive
// -------------------------



@include indent-responsive(pt, padding-top, $medias, $paddings);
@include indent-responsive(pb, padding-bottom, $medias, $paddings);

@include indent-responsive(pr, padding-right, $medias, $paddings-rl);
@include indent-responsive(pl, padding-left, $medias, $paddings-rl);

@include indent-responsive(mt, margin-top, $medias, $margins);
@include indent-responsive(mb, margin-bottom, $medias, $margins);

@include indent-responsive(ml, margin-left, $medias-no, $margins);
@include indent-responsive(mr, margin-right, $medias-no, $margins);


